<template>
  <div class="footer"  style=" background-color: #2d3640; ">
    <footer-nav></footer-nav>
  </div>
</template>

<script>
  import FooterNav from "../../components/shared/bottomFooterMobile";
  export default {
    name: "src-pages-footer-shared-v2_mobile",
    components: {FooterNav},
    data() {
      return {

      }
    },

    methods: {
    }
  }
</script>

<style>
  .footer{
    width: calc(100vw - 30px);
    background: #ffffff;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 30px 15px 30px 15px;
  }

  .footer ul{
    list-style-type:none;
  }

  .footer li, .footer p{
    display:inline-block;
    font-size: 12px;
    color: #868a8a;
  }

  .footer li:after{
    content:'•';
    padding:0 7px;
  }

  .footer li:last-child:after{
    content:'';
  }
</style>
