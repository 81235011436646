<template>
  <div class="footer-div">
    <div class="logo-div">
      <img src="https://gcdn.bionluk.com/site/general/bionluk_logo_gk_footer.png"/>
    </div>
    <div style="display: flex; flex-direction: row; flex: 2; flex-wrap:wrap;">
      <div class="sections-body" v-for="objOrder in Object.keys(footerLinks).length">
        <p class="section-header">{{footerLinks[Object.keys(footerLinks)[objOrder-1]].name}}</p>
        <router-link class="sections"
                     :key="'section1' + index"
                     v-for="(footerLink, index) in footerLinks[Object.keys(footerLinks)[objOrder-1]].list" :to="footerLink.url">{{footerLink.name}}
        </router-link>
      </div>
    </div>
    <div class="separator"></div>
    <div>
      <a href="https://facebook.com/bionluk" target="_blank" style="margin-right: 30px">
        <img src="https://gcdn.bionluk.com/site/icon/tansel_footer_facebook_white.svg"/>
      </a>
      <a href="https://twitter.com/bionluk" target="_blank" style="margin-right: 30px">
        <img src="https://gcdn.bionluk.com/site/icon/tansel_footer_twitter_white.svg"/>
      </a>
      <a href="https://instagram.com/bionlukcom" target="_blank">
        <img src="https://gcdn.bionluk.com/site/icon/tansel_footer_instagram_white.svg"/>
      </a>
    </div>
    <p style="font-size: 14px; color: #9da5af; margin-top: 20px; width: 129px;">Bionluk Bilgi Teknolojileri Paz. ve Tic. A.Ş. © 2024</p>
  </div>
</template>

<script>
  export default {
    name: "src-pages-components-shared-bottomFooterMobile",
    data() {
      return {
        footerLinks: {}
      };
    },

    methods: {},
    created() {
      this.footerLinks = this.$store.state.footerLinks;
    }
  }
</script>

<style scoped lang="scss">

  .footer-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .logo-div {
    display: flex;
    flex-direction: column;
    img {
      width: 129px;
      height: 29px;
    }
  }

  .sections-body {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .section-header {
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 29px;
    margin-top: 30px;
  }

  .sections {
    font-size: 16px;
    text-align: left;
    color: #d2dbe4;
    margin-bottom: 20px;
  }

  .separator {
    width: 100%;
    height: 2px;
    margin-bottom: 30px;
    margin-top: 30px;
    border-bottom: solid 1px #3a4653;
  }
</style>
